import MainnavA from './MainnavA';

class Mainnav {
  constructor(element) {
    this.element = element;
    this.buttonElement = element.querySelector('.button-hamburger');
    this.pagesElement = element.querySelector('.mainnav__pages');
    const aElements = this.pagesElement.querySelectorAll('a');

    this.buttonElement.addEventListener('click', () => {
      if (this.buttonElement.classList.contains('-open')) {
        this.close();
      } else {
        this.open();
      }
    });
    aElements.forEach((aElement) => {
      // eslint-disable-next-line no-new
      new MainnavA(aElement);
    });
  }

  open() {
    const { buttonElement, pagesElement } = this;

    buttonElement.classList.add('-open');
    pagesElement.classList.add('-open');
    document.body.classList.add('-no-scroll');
    document.scrollingElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  close() {
    const { buttonElement, pagesElement } = this;

    buttonElement.classList.remove('-open');
    pagesElement.classList.remove('-open');
    document.body.classList.remove('-no-scroll');
  }
}

const mainnavElement = document.querySelector('.mainnav');

// eslint-disable-next-line no-new
new Mainnav(mainnavElement);
