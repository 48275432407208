class Header {
  constructor(element) {
    this.element = element;
    const headerIllustrationElement = element.querySelector('.header__illustration');
    this.imgElements = headerIllustrationElement.querySelectorAll('img');
    this.currentImg = 0;
    this.isAnimating = false;
    this.isComplete = false;
    let imagesLoaded = 0;

    const checkForAnimation = () => {
      if (imagesLoaded === this.imgElements.length) {
        this.isComplete = true;
        this.animateIllustrations();
      }
    };

    headerIllustrationElement.addEventListener('mouseenter', this.animateIllustrations.bind(this));

    this.imgElements.forEach((imgElement) => {
      if (imgElement.complete) {
        imagesLoaded += 1;
        checkForAnimation();
      } else {
        imgElement.addEventListener('load', () => {
          imagesLoaded += 1;
          checkForAnimation();
        });
      }
    });
  }

  animateIllustrations() {
    if (this.isAnimating === false && this.isComplete === true) {
      this.isAnimating = true;
      this.showNextImg();
    }
  }

  showNextImg() {
    this.imgElements[this.currentImg].style.opacity = 0;
    this.currentImg += 1;
    if (this.currentImg < this.imgElements.length) {
      this.imgElements[this.currentImg].style.opacity = 1;
      setTimeout(this.showNextImg.bind(this), 100);
    } else {
      this.currentImg = 0;
      this.imgElements[this.currentImg].style.opacity = 1;
      this.isAnimating = false;
    }
  }
}

const headerElement = document.querySelector('.header');

// eslint-disable-next-line no-new
if (headerElement) {
  new Header(headerElement);
}
