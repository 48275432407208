class MainnavA {
  constructor(element) {
    this.element = element;
    this.currentStep = 0;
    this.timeout = null;
    element.addEventListener('mouseenter', () => {
      clearTimeout(this.timeout);
      this.animatHover('enter');
    });
    element.addEventListener('mouseleave', () => {
      clearTimeout(this.timeout);
      this.animatHover('leave');
    });
    element.addEventListener('click', () => {
      clearTimeout(this.timeout);
    });
  }

  animatHover(direction = 'enter') {
    const { element } = this;
    const strichSteps = 5;
    const nextStep = () => {
      element.classList.remove(`-s-${this.currentStep}`);
      if (direction === 'enter') {
        this.currentStep += 1;
      } else {
        this.currentStep -= 1;
      }
      element.classList.add(`-s-${this.currentStep}`);
      if (this.currentStep >= 0 && this.currentStep < strichSteps) {
        this.timeout = setTimeout(nextStep, 60);
      }
    };

    nextStep();
  }
}

export default MainnavA;
